import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const ImageCollageHorizontal = (module) => {
  const gap = module.gap

  const collage = module.images.map((image, index) => {
    return (
      <div key={index} className={`${gap ? 'my-2 md:my-0 md:m-5' : ''}`}>
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </div>
    )
  })
  return (
    <div
      className={`collageHorizontal grid grid-cols-1 md:grid-cols-3 my-52 md:my-96 ${
        gap ? 'm-5' : ''
      }`}
    >
      {collage}
    </div>
  )
}

export default ImageCollageHorizontal
