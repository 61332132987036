import React from 'react'
import { graphql } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import Navigation from '../components/Navigation'

//modules
import TextFull from '../components/TextFull'
import ImageFull from '../components/ImageFull'
import ImageMedium from '../components/ImageMedium'
import ImageSmall from '../components/ImageSmall'
import ImageCollageHorizontal from '../components/ImageCollageHorizontal'
import ImageCollageVertical from '../components/ImageCollageVertical'

const HomeIndex = ({ data, location }) => {
  const modules = data.allContentfulLayout.edges[0].node.contentModules
  const about = data.allContentfulLayout.edges[0].node.about.about

  const HomeLayout = modules.map((module, index) => {
    switch (module.type) {
      case 'ContentfulImageCollageHorizontal':
        return <ImageCollageHorizontal {...module} key={index} />
      case 'ContentfulImageCollageVertical':
        return <ImageCollageVertical {...module} key={index} />
      case 'ContentfulImageFull':
        return <ImageFull {...module} key={index} />
      case 'ContentfulImageMedium':
        return <ImageMedium {...module} key={index} />
      case 'ContentfulImageSmall':
        return <ImageSmall {...module} key={index} />
      case 'ContentfulTextFull':
        return <TextFull {...module} key={index} />
      default:
    }
  })

  return (
    <div id="top">
      <Navigation />
      <div>{HomeLayout}</div>
      <footer>
        <div className="grid grid-rows-1 md:grid-rows-1 md:grid-cols-6 md:justify-items-stretch pb-10">
          <nav
            id="about"
            className="md:sticky md:top-32 pl-10 text-lg md:text-xl lg:text-3xl"
          >
            About
          </nav>
          <div className="px-10 md:px-0 col-span-4 xl:col-span-2">{about}</div>
        </div>
        <div className="fixed bottom-0 right-0 m-1 p-3 md:p-5 md:m-3 text-xl border border-black w-max cursor-pointer backdrop-blur-xs	backdrop-filter">
          <AnchorLink to="#top" title="&uarr;" />
        </div>
        <div className="text-sm md:text-base text-center pb-3 pt-24 md:pt-80">
          Copyright &copy;2021 Mare Kuzmanovski <br />
          Design & Development by Jerome Braeken Design
        </div>
      </footer>
    </div>
  )
}

export default HomeIndex

export const pageQuery = graphql`
  query {
    allContentfulLayout {
      edges {
        node {
          about {
            about
          }
          contentModules {
            type: __typename
            ... on Node {
              ... on ContentfulImageCollageHorizontal {
                name
                gap
                images {
                  gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
              ... on ContentfulImageCollageVertical {
                name
                images {
                  gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
              ... on ContentfulImageFull {
                name
                text {
                  text
                }
                image {
                  gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
              ... on ContentfulImageMedium {
                name
                text {
                  text
                }
                image {
                  gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
              ... on ContentfulImageSmall {
                name
                text {
                  text
                }
                image {
                  gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
              ... on ContentfulTextFull {
                name
                paragraph {
                  paragraph
                }
              }
            }
          }
        }
      }
    }
  }
`
