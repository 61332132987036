import React from 'react'

const TextFull = (module) => {
  return (
    <div className="full grid grid-cols-12 my-52 md:my-96">
      <div className="col-start-3 col-span-8">
        <div className="justify">{module.paragraph.paragraph}</div>
      </div>
    </div>
  )
}

export default TextFull
