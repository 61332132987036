import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const ImageCollageHorizontal = (module) => {
  const collage = module.images.map((image, index) => {
    return (
      <div className="collageHorizontal grid grid-cols-12" key={index}>
        <div className="col-start-7 col-span-6 lg:col-start-9 lg:col-span-4">
          <GatsbyImage image={image.gatsbyImageData} alt="" />
        </div>
      </div>
    )
  })
  return <div className="collageHorizontal my-52 md:my-96">{collage}</div>
}

export default ImageCollageHorizontal
