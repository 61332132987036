import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ImageMedium = (module) => {
  const image = getImage(module.image)

  return (
    <div className="medium my-52 md:my-96">
      <div className="grid grid-cols-12">
        <div className="col-start-3 col-span-8">
          <GatsbyImage image={image} alt="" />
        </div>
      </div>
      {module.text && (
        <div className="grid grid-cols-12 mt-5">
          <div className="col-start-3 col-span-8">{module.text.text}</div>
        </div>
      )}
    </div>
  )
}

export default ImageMedium
