import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Navigation = () => {
  return (
    <nav
      role="navigation"
      aria-label="Main"
      className="navigation sticky top-0 z-50 text-lg lg:text-3xl"
    >
      <div className="grid grid-cols-6 justify-items-stretch px-5 pt-5 md:px-10 md:pt-10">
        <div className="cursor-default justify-self-start">
          Mare Kuzmanovski
        </div>
        <div className="col-start-3 col-span-3 justify-self-end underline">
          <a href="mailto:mare.kuzmanovski@gmail.com">Email</a>
        </div>
        <div className="col-start-6 col-span-1 justify-self-end">
          <AnchorLink to="#about" title="About" />
        </div>
      </div>
    </nav>
  )
}

export default Navigation
